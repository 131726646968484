//* Make Order Functions
    //* Make Order
    function dsh_make_order_update()
    {
        // Form Function
        $('form[id="editForm"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    user_transaction: 'required'
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    $.ajax({
                        url: form.action,
                        type: form.method,
                        data: new FormData(form),
                        contentType: false,
                        processData: false,
                        beforeSend: function() {
                            $('#editForm .btn-submit').html('Please wait...');
                            $('#editForm .btn-submit').prop('disabled', true);
                        },
                        success: function(data) {
                            if(data.status == 'success'){ 
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "success",
                                    message: data.message,
                                    timeout: 1700
                                });

                                setTimeout(function() {
                                    window.location.href = data.next;
                                }, 1700);
                            }else{
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                            }
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            $('#editForm .btn-submit').html('Submit');
                            $('#editForm .btn-submit').prop('disabled', false);
                            $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                        }
                    });
                    return false;                  
                },
            });
        });
    }
    //* Accomodation
    function dsh_make_order_accomodation_init()
    {
        // Group Radio
        $('.group-radio').on('change', function() {
            $('.child-radio').prop("checked", false); // Reset all child checkbox
            // Check if it's a parent or child being checked
            if ($(this).hasClass('parent-radio')) {
                $('.child-radio').prop('required', false); // Set all children to not required
                $(this).next('ul').find('.child-radio').prop('required', true);  // Set the children of the selected parent to required 
            } else if ($(this).hasClass('child-radio')) { 
                $(this).prop("checked", true); // Check the selected child
                $('#'+$(this).attr('target-parent')).prop('checked', true); // Check the selected parent
            }
        });

        // Date Change (Range Date)
        $(document).on('change', 'input[name="checkin"]', function(){
            var openDate = $(this).val();
            let maxCloseDate = new Date(openDate);
            $('input[name="checkout"]').attr("min", openDate);
            $('input[name="checkout"]').attr("value", openDate);
            maxCloseDate.setMonth(maxCloseDate.getMonth() + 1);
            $('input[name="checkout"]').attr("max", maxCloseDate);
        });

        // Search
        $('form[id="searchAccomodation"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    checkin: 'required',
                    checkout: 'required',
                    adult: {
                        required: true,
                        number: true
                    },
                    child: {
                        required: true,
                        number: true
                    }
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    return true;
                },
            });
        });
    }
    function dsh_make_order_accomodation_store()
    {
        // Form Function
        $('form[id="addForm"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    product: 'required',
                    product_item: 'required',
                    name: 'required',
                    email: { 
                        required: true,
                        email: true
                    },
                    phone: { 
                        required: true,
                        number: true
                    },
                    nationality: 'required'
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    $.ajax({
                        url: form.action,
                        type: form.method,
                        data: new FormData(form),
                        contentType: false,
                        processData: false,
                        beforeSend: function() {
                            $('#addForm .btn-submit').html('Please wait...');
                            $('#addForm .btn-submit').prop('disabled', true);
                        },
                        success: function(data) {
                            if(data.status == 'success'){ 
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "success",
                                    message: data.message,
                                    timeout: 1700
                                });

                                setTimeout(function() {
                                    window.location.href = data.next;
                                }, 1700);
                            }else{
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                            }
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            $('#addForm .btn-submit').html('Submit');
                            $('#addForm .btn-submit').prop('disabled', false);
                            $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                        }
                    });
                    return false;                  
                },
            });
        });
    }
    //* Tour Program
    function dsh_make_order_tour_program_store()
    {
        // Form Function
        $('form[id="addForm"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    product: 'required',
                    name: 'required',
                    email: { 
                        required: true,
                        email: true
                    },
                    phone: { 
                        required: true,
                        number: true
                    },
                    nationality: 'required',
                    tour_datetime: 'required',
                    tour_hotel_origin: 'required',
                    tour_hotel_room: 'required',
                    quantity: { 
                        required: true,
                        number: true
                    }
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    $.ajax({
                        url: form.action,
                        type: form.method,
                        data: new FormData(form),
                        contentType: false,
                        processData: false,
                        beforeSend: function() {
                            $('#addForm .btn-submit').html('Please wait...');
                            $('#addForm .btn-submit').prop('disabled', true);
                        },
                        success: function(data) {
                            if(data.status == 'success'){ 
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "success",
                                    message: data.message,
                                    timeout: 1700
                                });

                                setTimeout(function() {
                                    window.location.href = data.next;
                                }, 1700);
                            }else{
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                            }
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            $('#addForm .btn-submit').html('Submit');
                            $('#addForm .btn-submit').prop('disabled', false);
                            $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                        }
                    });
                    return false;                  
                },
            });
        });
    }
    //* Golf Packages
    function dsh_make_order_golf_packages_store()
    {
        // Form Function
        $('form[id="addForm"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    product: 'required',
                    name: 'required',
                    email: { 
                        required: true,
                        email: true
                    },
                    phone: { 
                        required: true,
                        number: true
                    },
                    nationality: 'required',
                    golf_datetime: 'required',
                    quantity: { 
                        required: true,
                        number: true
                    }
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    $.ajax({
                        url: form.action,
                        type: form.method,
                        data: new FormData(form),
                        contentType: false,
                        processData: false,
                        beforeSend: function() {
                            $('#addForm .btn-submit').html('Please wait...');
                            $('#addForm .btn-submit').prop('disabled', true);
                        },
                        success: function(data) {
                            if(data.status == 'success'){ 
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "success",
                                    message: data.message,
                                    timeout: 1700
                                });

                                setTimeout(function() {
                                    window.location.href = data.next;
                                }, 1700);
                            }else{
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                            }
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            $('#addForm .btn-submit').html('Submit');
                            $('#addForm .btn-submit').prop('disabled', false);
                            $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                        }
                    });
                    return false;                  
                },
            });
        });
    }
    //* SPA
    function dsh_make_order_spa_init()
    {
        // Group Radio
        $('.group-radio').on('change', function() {
            $('.child-radio').prop("checked", false); // Reset all child checkbox
            // Check if it's a parent or child being checked
            if ($(this).hasClass('parent-radio')) {
                $('.child-radio').prop('required', false); // Set all children to not required
                $(this).next('ul').find('.child-radio').prop('required', true);  // Set the children of the selected parent to required 
            } else if ($(this).hasClass('child-radio')) { 
                $(this).prop("checked", true); // Check the selected child
                $('#'+$(this).attr('target-parent')).prop('checked', true); // Check the selected parent
            }
        });
    }
    function dsh_make_order_spa_store()
    {
        // Form Function
        $('form[id="addForm"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    product: 'required',
                    product_item: 'required',
                    name: 'required',
                    email: { 
                        required: true,
                        email: true
                    },
                    phone: { 
                        required: true,
                        number: true
                    },
                    nationality: 'required',
                    spa_datetime: 'required',
                    quantity: { 
                        required: true,
                        number: true
                    }
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    $.ajax({
                        url: form.action,
                        type: form.method,
                        data: new FormData(form),
                        contentType: false,
                        processData: false,
                        beforeSend: function() {
                            $('#addForm .btn-submit').html('Please wait...');
                            $('#addForm .btn-submit').prop('disabled', true);
                        },
                        success: function(data) {
                            if(data.status == 'success'){ 
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "success",
                                    message: data.message,
                                    timeout: 1700
                                });

                                setTimeout(function() {
                                    window.location.href = data.next;
                                }, 1700);
                            }else{
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                            }
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            $('#addForm .btn-submit').html('Submit');
                            $('#addForm .btn-submit').prop('disabled', false);
                            $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                        }
                    });
                    return false;                  
                },
            });
        });
    }
    //* Optional Packages
    function dsh_make_order_optional_packages_store()
    {
        // Form Function
        $('form[id="addForm"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    product: 'required',
                    name: 'required',
                    email: { 
                        required: true,
                        email: true
                    },
                    phone: { 
                        required: true,
                        number: true
                    },
                    nationality: 'required',
                    opt_datetime: 'required',
                    quantity: { 
                        required: true,
                        number: true
                    }
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    $.ajax({
                        url: form.action,
                        type: form.method,
                        data: new FormData(form),
                        contentType: false,
                        processData: false,
                        beforeSend: function() {
                            $('#addForm .btn-submit').html('Please wait...');
                            $('#addForm .btn-submit').prop('disabled', true);
                        },
                        success: function(data) {
                            if(data.status == 'success'){ 
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "success",
                                    message: data.message,
                                    timeout: 1700
                                });

                                setTimeout(function() {
                                    window.location.href = data.next;
                                }, 1700);
                            }else{
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                            }
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            $('#addForm .btn-submit').html('Submit');
                            $('#addForm .btn-submit').prop('disabled', false);
                            $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                        }
                    });
                    return false;                  
                },
            });
        });
    }