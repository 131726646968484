//* Main Functions
    //* Global
        // Daterange
            $('.daterange').daterangepicker({
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Clear'
                }
            });
            $('.daterange').on('apply.daterangepicker', function(ev, picker) {
                $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
            });
            $('.daterange').on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
            });
        // End Daterange
        function convertToSlug(Text) {
            return Text.toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
        }
        function typing_delay(callback, ms) {
            var timer = 0;
            return function() {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    callback.apply(context, args);
                }, ms || 0);
            };
        }
    //* Form Ajax
        function form_ajax(form, form_id)
        {
            $.ajax({
                url: form.action,
                type: form.method,
                data: new FormData(form),
                contentType: false,
                processData: false,
                beforeSend: function() {
                    $(form_id+' .btn-submit').html('Please wait...');
                    $(form_id+' .btn-submit').prop('disabled', true);
                },
                success: function(data) {
                    if(data.status == 'success'){ 
                        SnackBar({
                            fixed: true,
                            position: "tr",
                            status: "success",
                            message: data.message,
                            timeout: 1700
                        });

                        setTimeout(function() {
                            window.location.href = data.next;
                        }, 1700);
                    }else{
                        $(form_id+' .btn-submit').html('Submit');
                        $(form_id+' .btn-submit').prop('disabled', false);
                        $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    $(form_id+' .btn-submit').html('Submit');
                    $(form_id+' .btn-submit').prop('disabled', false);
                    $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                }
            });
        }

    //* --------------
    //* Login Function
    //* --------------
        function login_store()
        {
            $('form[id="loginForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        email: {
                            required: true,
                            email: true,
                            maxlength: 150
                        },
                        password: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: $(form).serialize(),
                            cache: false,
                            processData: false,
                            beforeSend: function() {
                                $('#loginForm .btn-submit').html('Please wait...');
                                $('#loginForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){                                
                                    window.location.href = data.next;
                                }else{
                                    $('#loginForm .btn-submit').html('Sign In');
                                    $('#loginForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#loginForm .btn-submit').html('Sign In');
                                $('#loginForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Profile Function
    //* --------------
        // Update
        function dsh_profile_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        phone_number: 'required',
                        mobile_phone: 'required',
                        contact_person_name: 'required',
                        email: { 
                            required: true,
                            email: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Selling Item Function
    //* --------------
        // Store
        function dsh_contract_type_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        period: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_contract_type_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        period: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Selling Item Function
    //* --------------
        // Init
        function dsh_selling_item_init()
        {
            // Event contract type selected
            $('select[name="select_contract_type"]').on("change", function(e) {
                var data_period = $('option:selected', this).attr('data-period');
                var data_remarks = $('option:selected', this).attr('data-remarks');
                var data_selector = $('option:selected', this).attr('data-selector');

                $('.'+data_selector+'[name="period"]').attr('value', data_period);
                $('.'+data_selector+'[name="remarks"]').val(data_remarks);
            });
        }
        // Store
        function dsh_selling_item_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;                  
                    },
                });
            });

            // Field Validation
                {
                    $("input[name^=name]").each(function(index, value){
                        $(this).rules("add", {
                            required: true
                        });
                    });
                    $("input[name^=quantity]").each(function(index, value){
                        $(this).rules("add", {
                            required: true,
                            number: true
                        });
                    });
                    $("input[name^=rates]").each(function(index, value){
                        $(this).rules("add", {
                            required: true
                        });
                    });
                }
            // 

            // 
                {
                    var total_item = 1;
                    var total_replace_item = 0;

                    // Add Item
                    $(document).on('click', '.btn-add-item', function(e){
                        e.preventDefault();
                        total_replace_item += 1;

                        var itemField = $('form .row-item').last().html();
                        var releaseItemField = '<div class="row row-item row-item-'+(total_item + 1)+'">'+itemField.split('row-item-'+total_replace_item).join('row-item-'+(total_item + 1));
                        
                        $('form .inner-item').append(releaseItemField);
                        total_item += 1;
                    });

                    // Delete Item
                    $(document).on('click', '.btn-delete-item', function(e){
                        e.preventDefault();
                        var baseItem = $(this).attr('base-item');
                        $('form .row-item.'+baseItem).remove();

                        // 
                        $("form .row-item").removeClass (function (index, className) {
                            return (className.match (/(^|\s)row-item-\S+/g) || []).join(' ');
                        });
                        $("form .row-item .btn-delete-item").attr('base-item', '');

                        // Reordering
                        $("form .row-item").each(function(i, obj) {
                            total_item = (i + 1);
                            total_replace_item = i;
                            
                            $(this).addClass('row-item-'+total_item);
                            $(".btn-delete-item", this).attr('base-item', 'row-item-'+total_item);
                        });
                    });
                }
            // 
        }
        // Update
        function dsh_selling_item_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        quantity: { 
                            required: true,
                            number: true
                        },
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Manage Areas Function
    //* --------------
        // Store
        function dsh_areas_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_areas_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Manage Facilities Function
    //* --------------
        // Store
        function dsh_facilities_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_facilities_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Manage Categories Function
    //* --------------
        // Store
        function dsh_categories_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_categories_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Manage Tags Function
    //* --------------
        // Store
        function dsh_tags_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_tags_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Package Function
    //* --------------
        function dsh_package_init()
        {
            // Function change provit mark
            $(document).on('change', '[name="type_profit"]', function(){
                var data_addon = $(this).attr('data-addon');

                $('[id="basic-addon-profit-type"]').html(data_addon);
            });
        }
        // Store
        function dsh_package_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        profit_value: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_package_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        profit_value: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Package Function
    //* --------------
        function dsh_news_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_news_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Manage Agent Function
    //* --------------
        // Store
        function dsh_manage_agent_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        agent_name: 'required',
                        select_city: 'required',
                        email: { 
                            required: true,
                            email: true
                        },
                        phone_number: 'required',
                        mobile_number: 'required',
                        address: 'required',
                        staff_name: 'required',
                        director_name: 'required',
                        dob: 'required',
                        bank_name: 'required',
                        password: { 
                            required: true,
                            minlength: 4
                        },
                        status: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_manage_agent_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        agent_name: 'required',
                        select_city: 'required',
                        email: { 
                            required: true,
                            email: true
                        },
                        phone_number: 'required',
                        mobile_number: 'required',
                        address: 'required',
                        staff_name: 'required',
                        director_name: 'required',
                        dob: 'required',
                        bank_name: 'required',
                        password: { 
                            required: true,
                            minlength: 4
                        },
                        status: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Manage Supplier Function
    //* --------------
        // Store
        function dsh_manage_supplier_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        select_type: 'required',
                        supplier_name: 'required',
                        select_city: 'required',
                        email: { 
                            required: true,
                            email: true
                        },
                        mobile_number: 'required',
                        address: 'required',
                        staff_name: 'required',
                        position: 'required',
                        password: { 
                            required: true,
                            minlength: 4
                        },
                        status: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_manage_supplier_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        select_type: 'required',
                        supplier_name: 'required',
                        select_city: 'required',
                        email: { 
                            required: true,
                            email: true
                        },
                        mobile_number: 'required',
                        address: 'required',
                        staff_name: 'required',
                        position: 'required',
                        password: { 
                            required: true,
                            minlength: 4
                        },
                        status: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh User Affiliate Function
    //* --------------
        function dsh_user_affiliate_init()
        {
            // Function change revenue mark
            $(document).on('change', '[name="type_revenue"]', function(){
                var data_addon = $(this).attr('data-addon');

                $('[id="basic-addon-revenue-type"]').html(data_addon);
            });
            // Function change get special offers
            $(document).on('change', '[name="type_special_offer"]', function(){
                var data_addon = $(this).attr('data-addon');

                $('[id="basic-addon-special-offer"]').html(data_addon);
            });
        }
        // Store
        function dsh_user_affiliate_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        select_user: 'required',
                        revenue_value: 'required',
                        status: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_user_affiliate_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        revenue_value: 'required',
                        status: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Accommodation Function
    //* --------------
        // Store
        function dsh_accommodation_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_accommodation_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Accommodation Room Function
    //* --------------
        function dsh_accommodation_room_init()
        {
            // Date Change (Range Date)
            $(document).on('change', 'input[name="date"]', function(){
                var openDate = $(this).val();
                let maxCloseDate = new Date(openDate);
                console.log(openDate);
                $('input[name="until_date"]').attr("min", openDate);
                $('input[name="until_date"]').attr("value", openDate);
                maxCloseDate.setMonth(maxCloseDate.getMonth() + 1);
                console.log(maxCloseDate);
                $('input[name="until_date"]').attr("max", maxCloseDate);
            });
        }
        // Store
        function dsh_accommodation_room_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        adult_amount: {
                            required: true,
                            number: true
                        },
                        child_amount: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#addForm');
                        return false;
                    },
                });
            });
        }
        // Update
        function dsh_accommodation_room_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        adult_amount: {
                            required: true,
                            number: true
                        },
                        child_amount: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Accommodation Room Allotment Function
    //* --------------
        // Init
        function dsh_accommodation_room_allotment_init(fc_event_url)
        {
            // Function to load full calendar
            if($('#calendar').length)
            {
                var calendarEl = document.getElementById('calendar');

                var calendar = new FullCalendar.Calendar(calendarEl, {
                    plugins: [ 'interaction', 'dayGrid', 'timeGrid' ],
                        timeZone: 'UTC',
                        defaultView: 'dayGridMonth',
                        header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth'
                    },
                    editable: true,
                    events: fc_event_url,
                    selectable: true,
                    selectHelper: true,
                    select:function(start, end, allDay)
                    {
                        console.log('select');
                    },
                    eventClick:function(event)
                    {
                        info.jsEvent.preventDefault();

                        if (info.event.url) {
                            window.open(info.event.url);
                        }
                    }                
                });

                calendar.render();
            }
        }
        // Store
        function dsh_accommodation_room_allotment_store()
        {
            // Form Function
            $('form[id="allotmentAddForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        date: 'required',
                        allotment: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#allotmentAddForm');
                        return false;                  
                    },
                });
            });
        }
        // Bulk Update
        function dsh_accommodation_room_allotment_bulk_update()
        {
            // Form Function
            $('form[id="allotmentBulkEditForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        date: 'required',
                        allotment: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#allotmentBulkEditForm');
                        return false;                  
                    },
                });
            });
        }
        // Bulk Delete
        function dsh_accommodation_room_allotment_bulk_delete()
        {
            // Form Function
            $('form[id="allotmentBulkDeleteForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        date: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#allotmentBulkDeleteForm');
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_accommodation_room_allotment_update()
        {
            // Form Function
            $('form[id="allotmentEditForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        date: 'required',
                        allotment: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#allotmentEditForm');
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Withdraw Commission Function
    //* --------------
        // Update
        function dsh_withdraw_commission_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        status: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        form_ajax(form, form_id = '#editForm');
                        return false;
                    },
                });
            });
        }


    //* --------------
    //* Dsh Product Wellness Function
    //* --------------
        // Store
        function dsh_product_wellness_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        category: 'required',
                        position: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_product_wellness_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        category: 'required',
                        position: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Product Facility Function
    //* --------------
        // Store
        function dsh_product_facility_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        position: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_product_facility_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        position: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Product Area Function
    //* --------------
        // Store
        function dsh_product_area_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        position: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_product_area_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        position: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }

    //* --------------
    //* Dsh Product Brand Function
    //* --------------
        // Store
        function dsh_product_brand_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_product_brand_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Accomodation Function
    //* --------------
        // Store
        function dsh_accomodation_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        hotel_stars: {
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_accomodation_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        hotel_stars: {
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Accomodation Room Function
    //* --------------
        // Init
        function dsh_accomodation_room_init()
        {
            // Date Change (Range Date)
            $(document).on('change', 'input[name="date"]', function(){
                var openDate = $(this).val();
                let maxCloseDate = new Date(openDate);
                console.log(openDate);
                $('input[name="until_date"]').attr("min", openDate);
                $('input[name="until_date"]').attr("value", openDate);
                maxCloseDate.setMonth(maxCloseDate.getMonth() + 1);
                console.log(maxCloseDate);
                $('input[name="until_date"]').attr("max", maxCloseDate);
            });
        }
        // Store
        function dsh_accomodation_room_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required',
                        adult_amount: {
                            required: true,
                            number: true
                        },
                        child_amount: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_accomodation_room_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required',
                        adult_amount: {
                            required: true,
                            number: true
                        },
                        child_amount: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Accomodation Room Allotment Function
    //* --------------
        // Init
        function dsh_accomodation_room_allotment_init(fc_event_url)
        {
            // Function change voucher type
            $(document).on('change', '[name="type_voucher"]', function(){
                var data_addon = $(this).attr('data-addon');

                $('[id="basic-addon-voucher-type"]').html(data_addon);
            });

            // Function to load full calendar
            if($('#calendar').length)
            {
                var calendarEl = document.getElementById('calendar');

                var calendar = new FullCalendar.Calendar(calendarEl, {
                    plugins: [ 'interaction', 'dayGrid', 'timeGrid' ],
                        timeZone: 'UTC',
                        defaultView: 'dayGridMonth',
                        header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth'
                    },
                    editable: true,
                    events: fc_event_url,
                    selectable: true,
                    selectHelper: true,
                    select:function(start, end, allDay)
                    {
                        console.log('select');
                    },
                    eventClick:function(event)
                    {
                        info.jsEvent.preventDefault();

                        if (info.event.url) {
                            window.open(info.event.url);
                        }
                    }                
                });

                calendar.render();
            }
        }
        // Store
        function dsh_accomodation_room_allotment_store()
        {
            // Form Function
            $('form[id="allotmentAddForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        date: 'required',
                        allotment: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#allotmentAddForm .btn-submit').html('Please wait...');
                                $('#allotmentAddForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#allotmentAddForm .btn-submit').html('Submit');
                                    $('#allotmentAddForm .btn-submit').prop('disabled', false);
                                    $('#allotmentAddForm .alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#allotmentAddForm .btn-submit').html('Submit');
                                $('#allotmentAddForm .btn-submit').prop('disabled', false);
                                $('#allotmentAddForm .alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Bulk Update
        function dsh_accomodation_room_allotment_bulk_update()
        {
            // Form Function
            $('form[id="allotmentBulkEditForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        date: 'required',
                        allotment: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#allotmentBulkEditForm .btn-submit').html('Please wait...');
                                $('#allotmentBulkEditForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#allotmentBulkEditForm .btn-submit').html('Submit');
                                    $('#allotmentBulkEditForm .btn-submit').prop('disabled', false);
                                    $('#allotmentBulkEditForm .alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#allotmentBulkEditForm .btn-submit').html('Submit');
                                $('#allotmentBulkEditForm .btn-submit').prop('disabled', false);
                                $('#allotmentBulkEditForm .alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_accomodation_room_allotment_update()
        {
            // Form Function
            $('form[id="allotmentEditForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        date: 'required',
                        allotment: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#allotmentEditForm .btn-submit').html('Please wait...');
                                $('#allotmentEditForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#allotmentEditForm .btn-submit').html('Submit');
                                    $('#allotmentEditForm .btn-submit').prop('disabled', false);
                                    $('#allotmentEditForm .alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#allotmentEditForm .btn-submit').html('Submit');
                                $('#allotmentEditForm .btn-submit').prop('disabled', false);
                                $('#allotmentEditForm .alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Accomodation Room Voucher Function
    //* --------------
        // Store
        function dsh_accomodation_room_voucher_store()
        {
            // Form Function
            $('form[id="voucherAddForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        voucher_code: 'required',
                        date: 'required',
                        until_date: 'required',
                        total_allotment: {
                            required: true,
                            number: true
                        },
                        total_value: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#voucherAddForm .btn-submit').html('Please wait...');
                                $('#voucherAddForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#voucherAddForm .btn-submit').html('Submit');
                                    $('#voucherAddForm .btn-submit').prop('disabled', false);
                                    $('#voucherAddForm .alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#voucherAddForm .btn-submit').html('Submit');
                                $('#voucherAddForm .btn-submit').prop('disabled', false);
                                $('#voucherAddForm .alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Tour Program Function
    //* --------------
        // Store
        function dsh_tour_program_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required',
                        minimum_order: {
                            required: true,
                            number: true
                        },
                        total_availability: {
                            required: true,
                            number: true
                        },
                        hour_duration: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_tour_program_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required',
                        minimum_order: {
                            required: true,
                            number: true
                        },
                        total_availability: {
                            required: true,
                            number: true
                        },
                        hour_duration: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Tour Program Itinerary Function
    //* --------------
        // Store
        function dsh_tour_program_itinerary_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        time: 'required',
                        title: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_tour_program_itinerary_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        time: 'required',
                        title: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Tour Program Faq Function
    //* --------------
        // Store
        function dsh_tour_program_faq_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        title: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_tour_program_faq_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        title: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Golf Package Function
    //* --------------
        // Store
        function dsh_golf_package_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required',
                        minimum_order: {
                            required: true,
                            number: true
                        },
                        total_availability: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_golf_package_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required',
                        minimum_order: {
                            required: true,
                            number: true
                        },
                        total_availability: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh SPA Function
    //* --------------
        // Store
        function dsh_spa_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        minimum_order: {
                            required: true,
                            number: true
                        },
                        total_availability: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_spa_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        minimum_order: {
                            required: true,
                            number: true
                        },
                        total_availability: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh SPA Package Function
    //* --------------
        // Store
        function dsh_spa_package_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_spa_package_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Optional Package Function
    //* --------------
        // Store
        function dsh_optional_package_store()
        {
            // Form Function
            $('form[id="addForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required',
                        minimum_order: {
                            required: true,
                            number: true
                        },
                        total_availability: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#addForm .btn-submit').html('Please wait...');
                                $('#addForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#addForm .btn-submit').html('Submit');
                                    $('#addForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#addForm .btn-submit').html('Submit');
                                $('#addForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
        // Update
        function dsh_optional_package_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        name: 'required',
                        rates: 'required',
                        minimum_order: {
                            required: true,
                            number: true
                        },
                        total_availability: {
                            required: true,
                            number: true
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
    //* --------------
    //* Dsh Change Password Function
    //* --------------        
        // Update
        function dsh_change_password_update()
        {
            $('form[id="changePasswordForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        current_password: 'required',
                        password: {
                            required: true,
                            minlength: 5,
                        },
                        password_confirmation: {
                            required: true,
                            minlength: 5,
                            equalTo: "#password"
                        }
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#changePasswordForm .btn-submit').html('Please wait...');
                                $('#changePasswordForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){        
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#changePasswordForm .btn-submit').html('Submit');
                                    $('#changePasswordForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#changePasswordForm .btn-submit').html('Submit');
                                $('#changePasswordForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Exchange Rates Function
    //* --------------
        // Update
        function dsh_ex_rates_update()
        {
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        eur: 'required',
                        usd: 'required',
                        idr: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){        
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Voucher Function
    //* --------------
        // Init
        function dsh_voucher_init()
        {
            // Date Change (Range Date)
            $(document).on('change', 'input[name="date"]', function(){
                var openDate = $(this).val();
                let maxCloseDate = new Date(openDate);
                console.log(openDate);
                $('input[name="until_date"]').attr("min", openDate);
                $('input[name="until_date"]').attr("value", openDate);
                maxCloseDate.setMonth(maxCloseDate.getMonth() + 1);
                console.log(maxCloseDate);
                $('input[name="until_date"]').attr("max", maxCloseDate);
            });

            // Function change voucher type
            $(document).on('change', '[name="type_voucher"]', function(){
                var data_addon = $(this).attr('data-addon');

                $('[id="basic-addon-voucher-type"]').html(data_addon);
            });
        }
        // Update
        function dsh_voucher_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        date: 'required',
                        until_date: 'required',
                        total_allotment: {
                            required: true,
                            number: true
                        },
                        total_value: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('#editForm .alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('#editForm .alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;                  
                    },
                });
            });
        }
    //* --------------
    //* Dsh Order Action Function
    //* --------------
        // Update Change Period
        function dsh_order_action_change_period_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        checkin: 'required',
                        checkout: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }
        // Update Switch Accomodation
        function dsh_order_action_switch_init()
        {
            // Group Radio
            $('.group-radio').on('change', function() {
                $('.child-radio').prop("checked", false); // Reset all child checkbox
                // Check if it's a parent or child being checked
                if ($(this).hasClass('parent-radio')) {
                    $('.child-radio').prop('required', false); // Set all children to not required
                    $(this).next('ul').find('.child-radio').prop('required', true);  // Set the children of the selected parent to required 
                } else if ($(this).hasClass('child-radio')) { 
                    $(this).prop("checked", true); // Check the selected child
                    $('#'+$(this).attr('target-parent')).prop('checked', true); // Check the selected parent
                }
            });
        }
        function dsh_order_action_switch_update()
        {
            // Form Function
            $('form[id="editForm"]').each(function(){
                $(this).validate({
                    ignore: ".ignore",
                    rules: {
                        product: 'required',
                        product_item: 'required'
                    },
                    errorPlacement: function(error, element) {
                        var elementName = element.attr("base_error");
                        error.appendTo("#"+elementName);
                    },
                    submitHandler: function(form) {
                        $.ajax({
                            url: form.action,
                            type: form.method,
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            beforeSend: function() {
                                $('#editForm .btn-submit').html('Please wait...');
                                $('#editForm .btn-submit').prop('disabled', true);
                            },
                            success: function(data) {
                                if(data.status == 'success'){ 
                                    SnackBar({
                                        fixed: true,
                                        position: "tr",
                                        status: "success",
                                        message: data.message,
                                        timeout: 1700
                                    });

                                    setTimeout(function() {
                                        window.location.href = data.next;
                                    }, 1700);
                                }else{
                                    $('#editForm .btn-submit').html('Submit');
                                    $('#editForm .btn-submit').prop('disabled', false);
                                    $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                                }
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                $('#editForm .btn-submit').html('Submit');
                                $('#editForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                            }
                        });
                        return false;
                    },
                });
            });
        }