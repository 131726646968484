//* Post Functions
    //* Global
    function dsh_post_init()
    {
        // Summernote Editor
        $('.body-summernote').summernote({
            height: 300,
            disableDragAndDrop: true,
            followingToolbar: false,
    
            callbacks: {
                onPaste: function(e) {
                    var t = e.currentTarget.innerText;
                    var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                    e.preventDefault();
                    var maxPaste = bufferText.length;
                    document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
                },
                onImageUpload: function(files) {
                    data = new FormData();
                    data.append("image", files[0]);
    
                    $.ajax({
                        data: data,
                        type: "POST",
                        url: "/dashboard/admin/post/sm/image/store",
                        cache: false,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            if (data.act == 'success')
                                $('#body').summernote("insertImage", data.asset_url);
                            else{
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "danger",
                                    message: 'Problem. Image added was unsuccess',
                                    timeout: 1700
                                });
                            }
                        }
                    });
                },
                onMediaDelete : function(target) {
                    $.ajax({
                        data: {image:target[0].src},
                        type: "GET",
                        url: "/dashboard/admin/post/sm/image/destroy",
                        cache: false,
                        success: function(data) {
                            if (data.act != 'success')
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "danger",
                                    message: 'Problem. Image deleted was unsuccess',
                                    timeout: 1700
                                });
                        }
                    });
                }
            }
        });

        // Tag Select
        $('#select_tags').select2({
            maximumSelectionSize: 10,
            minimumResultsForSearch: Infinity,
            multiple: true,
            minimumInputLength: 1,
            placeholder: "Search Tag",
            closeOnSelect: false,
            id: function(i) {
                return i;
            },
            initSelection: function(element, callback) {},
            ajax: {
                type: 'POST',
                url: "/dashboard/admin/post/tag/search",
                allowClear: true,
                dataType: 'json',
                delay: 250,
                params: {
                    contentType: "application/json"
                },
                data: function (params) {
                    var query = {
                        search: params.term,
                        type: 'public'
                    }
                    return query;
                },
                results: function(data, page) {
                    return {
                        results: data
                    };
                },
                cache: false
            },
            formatResult: function(i) {
                return '<div>' + i.name + '</div>';
            }, // Formats results in drop down
            formatSelection: function(i) {
                return '<div>' + i.name + '</div>';
            }, //Formats result that is selected
            dropdownCssClass: "bigdrop", // apply css that makes the dropdown taller
            escapeMarkup: function(m) {
                return m;
            } // we do not want to escape markup since we are displaying html in results			
        });
    }